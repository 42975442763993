<template>
    <div class="container">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>管理后台</el-breadcrumb-item>
            <el-breadcrumb-item>情绪识别</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-row>
                <el-col :span="24">
                    <el-form :inline="true">
                        <el-form-item>
                            <el-input size="small" placeholder="姓名" v-model="searchName"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input size="small" placeholder="职务" v-model="searchJob"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-select v-model="searchDepartment1" size="small" placeholder="一级部门" :disabled="branchArr[0] ? true:false"  @change="choiceA">
                                <el-option
                                v-for="item in department1List"
                                :key="item.branch"
                                :label="item.branch"
                                :value="item.branch">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-select v-model="searchDepartment2" v-if="department2List.length>0" size="small" placeholder="二级部门" :disabled="branchArr[1] ? true:false" @change="choiceB">
                                <el-option
                                v-for="item in department2List"
                                :key="item.branch"
                                :label="item.branch"
                                :value="item.branch">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-select v-model="searchDepartment3" v-if="department3List.length>0" size="small" placeholder="三级部门" :disabled="branchArr[2]  ? true:false" @change="choiceC">
                                <el-option
                                v-for="item in department3List"
                                :key="item.branch"
                                :label="item.branch"
                                :value="item.branch">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-select v-model="searchDepartment4" v-if="department4List.length>0" :disabled="branchArr[3] ? true:false" size="small" placeholder="四级部门">
                                <el-option
                                v-for="item in department4List"
                                :key="item.branch"
                                :label="item.branch"
                                :value="item.branch">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-date-picker
                                v-model="emotionDate"
                                type="date"
                                size="small"
                                placeholder="选择日期"
                                value-format="timestamp"
                                :picker-options="pickerOptions">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" size="small"  @click="search">查询</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" size="small"  @click="reset">重置</el-button>
                        </el-form-item> 
                        <el-form-item>
                            <!-- <el-button type="success" size="small" v-if="personnelLink!=''"><el-link  :href="publicUrl+personnelLink" class="download">导出数据</el-link></el-button>                            -->
                            <el-button type="success" size="small" v-if="personnelLink!=''"><el-link  @click="exportsHzdata" class="download">导出数据</el-link></el-button>                            
                        </el-form-item>                      
                    </el-form>
                </el-col>
            </el-row>
            <el-table
                :data="personnelData"
                border
                stripe
                style="width: 100%">
                <el-table-column
                    prop="name"
                    label="姓名">
                </el-table-column>
                <el-table-column
                    prop="job"
                    label="职务">
                </el-table-column>
                <el-table-column
                    prop="branch_1"
                    label="一级部门">
                </el-table-column>
                <el-table-column
                    prop="branch_2"
                    label="二级部门">
                </el-table-column>
                <el-table-column
                    prop="branch_3"
                    label="三级部门">
                </el-table-column>
                <el-table-column
                    prop="branch_4"
                    label="四级部门">
                </el-table-column>
                <el-table-column
                    prop="emotion_mean"
                    label="心情指数">
                    <template slot-scope="scope">
                        <div>{{formatEmotion(scope.row.emotion_mean)}}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="tired_mean"
                    label="疲劳指数">
                    <template slot-scope="scope">
                        <div>{{formatTired(scope.row.tired_mean)}}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="stress_mean"
                    label="压力指数">
                    <template slot-scope="scope">
                        <div>{{formatStress(scope.row.stress_mean)}}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作">
                    <template slot-scope="scope">
                        <el-button type="success" size="mini" @click="preview(scope.row)">查看概览</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-dialog
            title="当日概览"
            :visible.sync="previewVisible"
            width="70%">
            <div class="chart-box">
                <div id="preview-chart"></div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="info" @click="closePreview">关闭</el-button>
                <el-button type="success" v-if="personnelDayLink!=''"><el-link  :href="publicUrl+personnelDayLink" class="download">导出数据</el-link></el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
	import XLSX from "xlsx";
import {department_list} from '../api/department'
import {emotion_list,person_day} from '../api/emotion'
import server_url from '../utils/baseUrl'
export default {
    data() {
        return {
            company:'',
            companyData:[],
            searchName:'',
            searchJob:'',
            emotionDate:'',
            pickerOptions:{  
                disabledDate(time) {
                    return time.getTime() >new Date().getTime();
                },
            },
            branchArr:[],
            department1List:[],
            department2List:[],
            department3List:[],
            department4List:[],
            searchDepartment1:null,
            searchDepartment2:null,
            searchDepartment3:null,
            searchDepartment4:null,
            personnelData:[],
            personnelLink:'',
            previewVisible:false,
            emotionAllHour:[],
            stressAllHour:[],
            tiredAllHour:[],
            preview_chart:'',
            publicUrl:server_url,
            personnelDayLink:''
        };
    },
    computed: {},
    methods: {
		exportsHzdata() {
			if (this.personnelData.length < 1) {
				return this.$message.info('暂无数据')
			}
			let dateStr = this.personnelData[0].dateStr
			let p = this.personnelData;
			let arrayData = [
				['时间','姓名','职务','一级部门','二级部门','三级部门','四级部门', '心情指数', '疲劳指数', '压力指数']
			];
			for (let i in p) {
				
				let row = [
					dateStr,p[i].nickname,p[i].job,p[i].branch_1,p[i].branch_2,p[i].branch_3,p[i].branch_4, p[i].emotion_mean, p[i].tired_mean, p[i].stress_mean
				];
				arrayData.push(row)
			}
			let arrayWorkSheet = XLSX.utils.aoa_to_sheet(arrayData);
			let workBook = {
				SheetNames: ['Sheet1'],
				Sheets: {
					'Sheet1': arrayWorkSheet
				},
			};
			let worksheet = workBook.Sheets['Sheet1'];
			XLSX.writeFile(workBook, "当日员工数据.xlsx");
		},
        departmentList(){
            department_list(this.company,this.searchDepartment1,this.searchDepartment2,this.searchDepartment3,this.searchDepartment4).then(res=>{
                if(res.status!=200){
                    return this.$message.error('获取部门列表失败')
                }
                let branch=res.data.branch;
                branch.forEach(item=>{
                    item.children=JSON.parse(item.children)
                })
                this.companyData=branch;
                this.department1List=branch;                    
                //管理员部门查询筛选权限
                let branch_content=sessionStorage.getItem('branch-content')
                let branch_level=sessionStorage.getItem('level')
                let branchArr=[];
                if(branch_level==1){
                    this.branchArr=[]
                }else{
                    branchArr=branch_content.split('|');
                    this.branchArr=branchArr;
                }
                if(branchArr[0]){
                    this.searchDepartment1=branchArr[0]
                    this.department1List.forEach(item=>{
                        if(item.branch==this.searchDepartment1){
                            this.department2List=item.children
                        }
                    })
                }
                if(branchArr[1]){
                    this.searchDepartment2=branchArr[1]
                    this.department2List.forEach(item=>{
                        if(item.branch==this.searchDepartment2){
                            this.department3List=item.children
                        }
                    })
                }
                if(branchArr[2]){
                    this.searchDepartment3=branchArr[2]
                    this.department3List.forEach(item=>{
                        if(item.branch==this.searchDepartment3){
                            this.department4List=item.children
                        }
                    })
                }
                if(branchArr[3]){
                    this.searchDepartment4=branchArr[3]
                } 
                this.personnelList()          
            })
        },
        personnelList(){           
            emotion_list(this.company,this.searchName,this.searchJob,this.searchDepartment1,this.searchDepartment2,this.searchDepartment3,this.searchDepartment4,this.emotionDate).then(res=>{
                if(res.status!=200){
                    return this.$message.error(res.msg||'获取人员列表失败')
                }
                this.personnelData=res.data.dayIndex;
                this.personnelLink=res.data.url;
            })
        },
        formatEmotion(index){
            if(index>=0&&index<=49){                
                return index+'(较差)'
            }
            if(index>=50&&index<=69){
                return index+'(一般)'
            }
            if(index>=70&&index<=100){
                return index+'(愉悦)'
            }
        },
        formatStress(index){
            if(index>=0&&index<=49){                
                return index+'(放松)'
            }
            if(index>=50&&index<=69){
                return index+'(稍有压力)'
            }
            if(index>=70&&index<=100){
                return index+'(压力山大)'
            }
        },
        formatTired(index){
            if(index>=0&&index<=49){                
                return index+'(精力充沛)'
            }
            if(index>=50&&index<=69){
                return index+'(有点疲惫)'
            }
            if(index>=70&&index<=100){
                return index+'(十分疲惫)'
            }
        },
        choiceA(){
            this.searchDepartment2=null
            this.searchDepartment3=null
            this.searchDepartment4=null
            this.department2List=[]
            this.department3List=[]
            this.department4List=[]
            this.department1List.forEach(item=>{
                if(item.branch==this.searchDepartment1){
                    this.department2List=item.children
                }
            })
        },
        choiceB(){
            this.searchDepartment3=null
            this.searchDepartment4=null
            this.department3List=[]
            this.department4List=[]
            this.department2List.forEach(item=>{
                if(item.branch==this.searchDepartment2){
                    this.department3List=item.children
                }
            })
        },
        choiceC(){
            this.searchDepartment4=null
            this.department4List=[]
            this.department3List.forEach(item=>{
                if(item.branch==this.searchDepartment3){
                    this.department4List=item.children
                }
            })
        }, 
        reset(){
            this.searchName='';
            this.searchJob='';
            if(this.branchArr.length==0){
                this.searchDepartment1=null
                this.searchDepartment2=null
                this.searchDepartment3=null
                this.searchDepartment4=null
                this.department2List=[]
                this.department3List=[]
                this.department4List=[]
            }
            if(this.branchArr.length==1){
                this.searchDepartment2=null
                this.searchDepartment3=null
                this.searchDepartment4=null
                //this.department2List=[]
                this.department3List=[]
                this.department4List=[]
            }
            if(this.branchArr.length==2){
                this.searchDepartment3=null
                this.searchDepartment4=null
                // this.department3List=[]
                this.department4List=[]
            }
            if(this.branchArr.length==3){
                this.searchDepartment4=null
                //this.department4List=[]
            }
            this.emotionDate=new Date().getTime();
            this.personnelList()
        },
        search(){
            this.personnelList()
        },
        previewData(imei){
            person_day(imei,this.emotionDate).then(res=>{
                if(res.status!=200){
                    return this.$message.error('获取概览失败')
                }
                this.emotionAllHour=res.data.emotionAllHour;
                this.stressAllHour=res.data.stressAllHour;
                this.tiredAllHour=res.data.tiredAllHour;
                this.personnelDayLink=res.data.url;
                this.$nextTick(()=>{
                    this.previewChart()
                }) 
            })
        },
        preview(row){
            this.previewVisible=true;
            this.previewData(row.imei)                       
        },
        previewChart(){
            let chartDom=document.getElementById('preview-chart') 
            if(this.preview_chart!=''){
                this.preview_chart.dispose()
            }
            this.preview_chart = this.$echarts.init(chartDom);
            let emotionAllHour=this.emotionAllHour;
            let stressAllHour=this.stressAllHour;
            let tiredAllHour=this.tiredAllHour;
            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                legend: {
                    data: ['心情', '压力', '疲劳']
                },
                xAxis: [
                    {
                        type: 'category',
                        data: ['0', '1', '2', '3', '4', '5', '6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
                        axisPointer: {
                            type: 'shadow'
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        max:100
                    },
                ],
                series: [
                    {
                        name: '心情',
                        type: 'line',
                        barGap: 0,
                        symbol: 'circle',
                        symbolSize: 10,
                        emphasis: {
                            focus: 'series'
                        },
                        data: emotionAllHour
                    },
                    {
                        name: '压力',
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        },
                        data: stressAllHour
                    },
                    {
                        name: '疲劳',
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        },
                        data: tiredAllHour
                    },
                ]
            };
            this.preview_chart.clear()
            this.preview_chart.setOption(option);
        },
        closePreview(){
            this.previewVisible=false
        }
    },
    created() {
        let company=sessionStorage.getItem('company')
        this.company=company; 
        this.emotionDate=new Date().getTime();
        this.departmentList();
    },
};
</script>
<style lang='less' scoped>
.chart-box{
    width: 100%;
    height: 400px;
}
#preview-chart{
    width: 100%;
    height: 100%;
}
</style>